<template>
  <Panel :header="isAdmin ? 'Masters production / payments' : 'Masters production'" :toggleable="true" :collapsed="isCollapsed">
    <template v-if="mastersProduction?.length">
      <div class="datatable-wrapper">
        <table class="p-datatable p-datatable-striped p-datatable-sm p-datatable-hoverable-rows positions-table">
          <thead class="p-datatable-tbody">
            <tr class="table-opened-info__header-row">
              <th>Master</th>
              <th>Time</th>
              <th v-if="isAdmin">Sum</th>
            </tr>
          </thead>
          <tbody class="p-datatable-tbody">
            <tr v-for="(statistics, index) of mastersProduction" :key="index">
              <td :class="{'no-bottom-border': index === mastersProduction.length - 1 && !isAdmin}">{{ statistics.name }}</td>
              <td :class="{'no-bottom-border': index === mastersProduction.length - 1 && !isAdmin}">
                <span>{{ formatDecimal(statistics.time) }} {{ $t('h') }}.</span>
              </td>
              <td v-if="isAdmin" :class="{'no-bottom-border': index === mastersProduction.length - 1 && !isAdmin}">{{ formatCurrency(statistics.sum) }}</td>
            </tr>
            <tr v-if="isAdmin" class="table-opened-info__row--total">
              <td class="no-bottom-border">Total</td>
              <td class="no-bottom-border">{{ formatDecimal(computedTotalTime) }} {{ $t('h') }}.</td>
              <td class="no-bottom-border">{{ formatCurrency(computedPaymentsSum) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <div v-else style="text-align: center; padding: 20px;">
      {{ $t('No data available' )}}
    </div>
  </Panel>
</template>

<script>
  import formatMixins from "@/mixins/formatMixins";
  export default {
    mixins: [ formatMixins ],
    name: 'ManagerCommonPanel',
    props: {
      mastersProduction: {
        type: Array,
      },
      isAdmin: {
        type: Boolean,
        default: true
      }
    },
    data: () => {
      return {
        isCollapsed: false,
      }
    },
    computed: {
      computedTotalTime() {
        if (!this.mastersProduction?.length) return 0
        return this.mastersProduction.reduce((sum, a) => sum + a.time, 0)
      },
      computedPaymentsSum() {
        if (!this.mastersProduction?.length) return 0
        return this.mastersProduction.reduce((sum, a) => sum + a.sum, 0)
      }
    },
  }
</script>