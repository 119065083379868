<template>
  <table class="p-datatable p-datatable-sm p-datatable-hoverable-rows positions-table">
    <thead class="p-datatable-thead">
    <tr class="table-opened-info__header-row">
      <th style="width: 18%">{{ manager.name }}</th>
      <th style="width: 10%">Count</th>
      <th style="width: 12%">Purchase price</th>
      <th style="width: 12%">Sell price</th>
      <th style="width: 12%">Tax sum</th>
      <th style="width: 12%">Discount sum</th>
      <th style="width: 12%">Margin</th>
      <th style="width: 12%">Profit</th>
    </tr>
    </thead>
    <tbody class="p-datatable-tbody">
    <template v-if="manager.services?.count">
      <tr>
        <td>
          <div class="p-d-flex p-ai-center">
            <a href="#" @click.prevent="toggleShowPositons(1)" class="p-mr-2">
              <i style="font-size: 0.9em" class="p-mr-1 pi" :class="positionTypes[1].showPositions ? 'pi-minus': 'pi-plus'"></i>
              <span>Services</span>
            </a>
          </div>
        </td>
        <td>{{ manager.services?.count }}</td>
        <td>{{ formatCurrency(manager.services?.purchase_sum) }}</td>
        <td>{{ formatCurrency(manager.services?.sell_price) }}</td>
        <td>{{ formatCurrency(manager.services?.tax_sum) }}</td>
        <td>{{ formatCurrency(manager.services?.discount_sum) }}</td>
        <td>{{ calculateMargin(manager.services) }}</td>
        <td>{{ formatCurrency(manager.services?.sell_price - manager.services?.purchase_sum) }}</td>
      </tr>
      <tr v-if="positionTypes[1].showPositions" class="table-opened-info">
        <td colspan="20" style="padding:0!important;">
          <ManagerSalesPositionsTable :isSpoilerTable="true" :isLoading="positionTypes[1].isLoading" :positions="positionTypes[1].positions"/>
        </td>
      </tr>
    </template>

    <template v-if="manager.requests?.count">
      <tr>
        <td>
          <div class="p-d-flex p-ai-center">
            <a href="#" @click.prevent="toggleShowPositons(2)" class="p-mr-2">
              <i style="font-size: 0.9em" class="p-mr-1 pi" :class="positionTypes[2].showPositions ? 'pi-minus': 'pi-plus'"></i>
              <span>Requests</span>
            </a>
          </div>
        </td>
        <td>{{ manager.requests?.count }}</td>
        <td>{{ formatCurrency(manager.requests?.purchase_sum) }}</td>
        <td>{{ formatCurrency(manager.requests?.sell_price) }}</td>
        <td>{{ formatCurrency(manager.requests?.tax_sum) }}</td>
        <td>{{ formatCurrency(manager.requests?.discount_sum) }}</td>
        <td>{{ calculateMargin(manager.requests) }}</td>
        <td>{{ formatCurrency(manager.requests?.sell_price - manager.requests?.purchase_sum) }}</td>
      </tr>
      <tr v-if="positionTypes[2].showPositions" class="table-opened-info">
        <td colspan="20" style="padding:0!important;">
          <ManagerSalesPositionsTable :isSpoilerTable="true" :isLoading="positionTypes[2].isLoading" :positions="positionTypes[2].positions"/>
        </td>
      </tr>
    </template>

    <template v-if="manager.warehouse_positions?.count">
      <tr>
        <td>
          <div class="p-d-flex p-ai-center">
            <a href="#" @click.prevent="toggleShowPositons(3)" class="p-mr-2">
              <i style="font-size: 0.9em" class="p-mr-1 pi" :class="positionTypes[3].showPositions ? 'pi-minus': 'pi-plus'"></i>
              <span>Warehouse items</span>
            </a>
          </div>
        </td>
        <td>{{ manager.warehouse_positions?.count }}</td>
        <td>{{ formatCurrency(manager.warehouse_positions?.purchase_sum) }}</td>
        <td>{{ formatCurrency(manager.warehouse_positions?.sell_price) }}</td>
        <td>{{ formatCurrency(manager.warehouse_positions?.tax_sum) }}</td>
        <td>{{ formatCurrency(manager.warehouse_positions?.discount_sum) }}</td>
        <td>{{ calculateMargin(manager.warehouse_positions) }}</td>
        <td>{{ formatCurrency(manager.warehouse_positions?.sell_price - manager.warehouse_positions?.purchase_sum) }}</td>
      </tr>
      <tr v-if="positionTypes[3].showPositions" class="table-opened-info">
        <td colspan="20" style="padding:0!important;">
          <ManagerSalesPositionsTable :isSpoilerTable="true" :isLoading="positionTypes[3].isLoading" :positions="positionTypes[3].positions"/>
        </td>
      </tr>
    </template>

    <template v-if="manager.free_positions?.count">
      <tr>
        <td>
          <div class="p-d-flex p-ai-center">
            <a href="#" @click.prevent="toggleShowPositons(4)" class="p-mr-2">
              <i style="font-size: 0.9em" class="p-mr-1 pi" :class="positionTypes[4].showPositions ? 'pi-minus': 'pi-plus'"></i>
              <span>Free positions</span>
            </a>
          </div>
        </td>
        <td>{{ manager.free_positions?.count }}</td>
        <td>{{ formatCurrency(manager.free_positions?.purchase_sum) }}</td>
        <td>{{ formatCurrency(manager.free_positions?.sell_price) }}</td>
        <td>{{ formatCurrency(manager.free_positions?.tax_sum) }}</td>
        <td>{{ formatCurrency(manager.free_positions?.discount_sum) }}</td>
        <td>{{ calculateMargin(manager.free_positions) }}</td>
        <td>{{ formatCurrency(manager.free_positions?.sell_price - manager.free_positions?.purchase_sum) }}</td>
      </tr>
      <tr v-if="positionTypes[4].showPositions" class="table-opened-info">
        <td colspan="20" style="padding:0!important;">
          <ManagerSalesPositionsTable :isSpoilerTable="true" :isLoading="positionTypes[4].isLoading" :positions="positionTypes[4].positions"/>
        </td>
      </tr>
    </template>
    <tr class="table-opened-info__row--total">
      <td class="no-bottom-border">Total</td>
      <td class="no-bottom-border">{{ calculateCount(manager) }}</td>
      <td class="no-bottom-border">{{ formatCurrency(calculatePurchaseSum(manager)) }}</td>
      <td class="no-bottom-border">{{ formatCurrency(calculateSellSum(manager)) }}</td>
      <td class="no-bottom-border">{{ formatCurrency(calculateTaxSum(manager)) }}</td>
      <td class="no-bottom-border">{{ formatCurrency(calculateTotalDiscountSum(manager)) }}</td>
      <td class="no-bottom-border"></td>
      <td class="no-bottom-border">{{ formatCurrency(calculateTotalProfit(manager)) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import formatMixins from "@/mixins/formatMixins";
import ManagerSalesPositionsTable from "@/pages/statistics/components/ManagerSalesPositionsTable";
import httpClient from "@/services/http.services";
import httpMixins from "@/mixins/httpMixins";

export default {
  mixins: [ formatMixins, httpMixins ],
  components: { ManagerSalesPositionsTable },
  name: 'ManagerSalesPanelTable',
  props: {
    manager: {
      type: Object,
    },
    fromTimestamp: [Number, null],
    toTimestamp: [Number, null],
  },
  watch: {
    fromTimestamp() {
      this.restorePositionTypesData()
    },
    toTimestamp() {
      this.restorePositionTypesData()
    }
  },
  data: () => {
    return {
      positionTypes: {
        1: {
          showPositions: false,
          positions:  null,
          isLoading: false,
        },
        2: {
          showPositions: false,
          positions:  null,
          isLoading: false,
        },
        3: {
          showPositions: false,
          positions:  null,
          isLoading: false,
        },
        4: {
          showPositions: false,
          positions:  null,
          isLoading: false,
        },
      },
      // showPositions: {
      //   1: false,
      //   2: false,
      //   3: false,
      //   4: false,
      // },
      // isLoading: {
      //   1: false,
      //   2: false,
      //   3: false,
      //   4: false,
      // },
      // showServicePositions: false,
      servicePositions: [],
      // showRequestsPositions: false,
      requestsPositions: [],
      // showWarehousePositions: false,
      warehousePositions: [],
      // showFreePositions: false,
      freePositions: [],
      isCollapsed: false,
    }
  },
  methods: {
    restorePositionTypesData() {
      this.positionTypes = {
        1: {
          showPositions: false,
          positions: null,
          isLoading: false,
        },
        2: {
          showPositions: false,
          positions: null,
          isLoading: false,
        },
        3: {
          showPositions: false,
          positions: null,
          isLoading: false,
        },
        4: {
          showPositions: false,
          positions: null,
          isLoading: false,
        }
      }
    },
    toggleShowPositons(type) {
      // if (type === 1) {
      //   if (!this.showServicePositions) {
      //     this.getPositions(1)
      //   }
      //   this.showServicePositions = !this.showServicePositions
      // } else if (type === 2) {
      //   this.showRequestsPositions = !this.showRequestsPositions
      // } else if (type === 3) {
      //   this.showWarehousePositions = !this.showWarehousePositions
      // } else if (type === 4) {
      //   this.showFreePositions = !this.showFreePositions
      // }
      if (!this.positionTypes[type].showPositions) {
        this.positionTypes[type].isLoading = true
        this.getPositions(type)
      }
      this.positionTypes[type].showPositions = !this.positionTypes[type].showPositions
    },
    async getPositions(type) {
      const obj = {
        type,
        userId: this.manager?.id,
        fromTimestamp: this.fromTimestamp,
        toTimestamp: this.toTimestamp
      }

      try {
        const { status, data } = await httpClient.post('statistics/get-user-positions?expand=order', obj)
        if (status === 200 && data) {
          this.positionTypes[type].positions = data
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.positionTypes[type].isLoading = false
      }
    },
    calculateMargin(position) {
      if (!position) return
      const margin = ((position.sell_price * 100) / position.purchase_sum) - 100

      if (+margin && isFinite(+margin)) {
        // return (+margin).toFixed(2) + '%'
        return this.formatDecimal(margin) + '%'
      } else {
        return '-'
      }
    },
    calculateCount(manager) {
      if (!manager) return 0
      const servicesCount = manager.services?.count ?? 0
      const requestsCount = manager.requests?.count ?? 0
      const warehousePositionsCount = manager.warehouse_positions?.count ?? 0
      const freePositionsCount = manager.free_positions?.count ?? 0
      // const consumablesCount = manager.consumables?.count ?? 0
      return +servicesCount + +requestsCount + +warehousePositionsCount + +freePositionsCount
    },
    calculatePurchaseSum(manager) {
      if (!manager) return 0
      const servicesPurchaseSum = manager.services?.purchase_sum ?? 0
      const requestsPurchaseSum = manager.requests?.purchase_sum ?? 0
      const warehousePositionsPurchaseSum = manager.warehouse_positions?.purchase_sum ?? 0
      const freePositionsPurchaseSum = manager.free_positions?.purchase_sum ?? 0
      // const consumablesPurchaseSum = manager.consumables?.purchase_sum ?? 0
      return +servicesPurchaseSum + +requestsPurchaseSum + +warehousePositionsPurchaseSum + +freePositionsPurchaseSum
    },
    calculateSellSum(manager) {
      if (!manager) return 0
      const servicesSellSum = manager.services?.sell_price ?? 0
      const requestsSellSum = manager.requests?.sell_price ?? 0
      const warehousePositionsSellSum = manager.warehouse_positions?.sell_price ?? 0
      const freePositionsSellSum = manager.free_positions?.sell_price ?? 0
      // const consumablesSellSum = manager.consumables?.sell_price ?? 0
      return +servicesSellSum + +requestsSellSum + +warehousePositionsSellSum + +freePositionsSellSum
    },
    calculateTaxSum(manager) {
      if (!manager) return 0
      const servicesTaxSum = manager.services?.tax_sum ?? 0
      const requestsTaxSum = manager.requests?.tax_sum ?? 0
      const warehousePositionsTaxSum = manager.warehouse_positions?.tax_sum ?? 0
      const freePositionsTaxSum = manager.free_positions?.tax_sum ?? 0
      // const consumablesTaxSum = manager.consumables?.tax_sum ?? 0
      return +servicesTaxSum + +requestsTaxSum + +warehousePositionsTaxSum + +freePositionsTaxSum
    },
    calculateTotalDiscountSum(manager) {
      if (!manager) return 0
      const servicesDiscountSum = manager.services?.discount_sum ?? 0
      const requestsDiscountSum = manager.requests?.discount_sum ?? 0
      const warehousePositionsDiscountSum = manager.warehouse_positions?.discount_sum ?? 0
      const freePositionsDiscountSum = manager.free_positions?.discount_sum ?? 0
      // const consumablesDiscountSum = manager.consumables?.discount_sum ?? 0
      return +servicesDiscountSum + +requestsDiscountSum + +warehousePositionsDiscountSum + +freePositionsDiscountSum
    },
    calculateTotalProfit(manager) {
      if (!manager) return

      let profit = 0
      if (manager.services) {
        // profit += +manager.services.sell_price - +manager.services.purchase_sum - +manager.services.discount_sum
        profit += +manager.services.sell_price - +manager.services.purchase_sum
      }

      if (manager.requests) {
        // profit += +manager.requests.sell_price - +manager.requests.purchase_sum - +manager.requests.discount_sum
        profit += +manager.requests.sell_price - +manager.requests.purchase_sum
      }

      if (manager.warehouse_positions) {
        // profit += +manager.warehouse_positions.sell_price - +manager.warehouse_positions.purchase_sum - +manager.warehouse_positions.discount_sum
        profit += +manager.warehouse_positions.sell_price - +manager.warehouse_positions.purchase_sum
      }

      if (manager.free_positions) {
        // profit += +manager.free_positions.sell_price - +manager.free_positions.purchase_sum - +manager.free_positions.discount_sum
        profit += +manager.free_positions.sell_price - +manager.free_positions.purchase_sum
      }

      return profit
    },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
}
</style>