<template>
  <Panel header="Managers sales" toggleable :collapsed="isCollapsed" @toggle="togglePanel">
    <Spinner v-if="showSpinner" :isSmall="true" :spinnerStyle="'margin: 20px auto'"></Spinner>
    <template v-else-if="managerSales?.length">
      <div v-for="(manager, index) of managerSales"
           :key="index"
           class="datatable-wrapper"
           :class="{'p-mt-5': managerSales.length > 1 && index > 0}">
        <ManagerSalesPanelTable :fromTimestamp="fromTimestamp"
                                :toTimestamp="toTimestamp"
                                :manager="manager"/>
      </div>
    </template>
    <div v-else style="text-align: center; padding: 20px;">
      {{ $t('No data available' )}}
    </div>
  </Panel>
</template>

<script>
// import formatMixins from "@/mixins/formatMixins";
import ManagerSalesPanelTable from "@/pages/statistics/components/ManagerSalesPanelTable";
import httpClient from "@/services/http.services";

export default {
  // mixins: [ formatMixins ],
  components: { ManagerSalesPanelTable },
  name: 'ManagerSalesPanel',
  props: {
    // managerSales: {
    //   type: Array,
    // },
    dataTableIsLoading: Boolean,
    fromTimestamp: [Number, null],
    toTimestamp: [Number, null],
  },
  watch: {
    dataTableIsLoading(boolean) {
      if (boolean) {
        this.isCollapsed = true
      }
    },
  },
  data: () => {
    return {
      managerSales: [],
      showSpinner: false,
      // showServicePositions: false,
      // servicePositions: [],
      // showRequestsPositions: false,
      // requestsPositions: [],
      // showWarehousePositions: false,
      // warehousePositions: [],
      // showFreePositions: false,
      // freePositions: [],
      isCollapsed: true,
    }
  },
  methods: {
    togglePanel(event) {
      if (!event) return
      const value = !event.value
      this.isCollapsed = !value
      if (value) {
        this.getStatistics()
      }
    },
    async getStatistics() {
      this.showSpinner = true
      try {
        const { status, data } = await httpClient({
          url: 'statistics/get-manager-sales-statistics',
          method: 'post',
          // params: {
          data: {
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })

        if (status === 200 && data) {
          this.managerSales = data.manager_sales || []
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.showSpinner = false
      }
    },
  }
}
</script>

<style scoped lang="scss">
.datatable-wrapper {
  overflow-y: auto;
}
</style>