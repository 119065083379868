<template>

	<div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <Toolbar class="p-mb-4">
<!--          <template v-slot:left>-->
          <template #start>
<!--            <div style="font-size: 1.4em;" v-if="isAdmin || isModerator">Statistics</div>-->
<!--            <div style="font-size: 1.4em;" v-else-if="isMaster && $store.state.user">{{ $t('Dashboard') }}</div>-->
            <div style="font-size: 1.4em;">{{ $t('Dashboard') }}</div>
          </template>
<!--          <template v-slot:right>-->
          <template #end v-if="isAdmin || isModerator">
            <DatesRangeCalendar :minDate="computedMinDate" :disabled="dataTableIsLoading" @change-dates-range="changeDatesRange"/>
          </template>
        </Toolbar>

        <Spinner v-if="isLoading"></Spinner>
        <div v-else-if="isAdmin" :class="{'datatable-is-loading':dataTableIsLoading}">
          <InvoicesPanel class="p-mb-3" :invoices="adminStatistics.invoices"/>

          <PaymentsPanel class="p-mb-3" :payments="adminStatistics.payments"/>

          <ConsumablesPanel class="p-mb-3" :consumablesStat="adminStatistics.consumablesStat"/>

          <ManagerCommonPanel class="p-mb-3" :managerCommonStat="adminStatistics.managerCommonStat"/>

<!--          <ManagerSalesPanel class="p-mb-3"-->
<!--                             :managerSales="adminStatistics.managerSales"-->
<!--                            :fromTimestamp="fromTimestamp"-->
<!--                            :toTimestamp="toTimestamp"/>-->

          <ManagerSalesPanel class="p-mb-3"
                             :dataTableIsLoading="dataTableIsLoading"
                             :fromTimestamp="fromTimestamp"
                             :toTimestamp="toTimestamp"/>

          <MastersProductionPanel :mastersProduction="adminStatistics.mastersProduction"/>
        </div>
        <div v-else-if="isModerator" :class="{'datatable-is-loading':dataTableIsLoading}">
          <ManagerPositionsPanel class="p-mb-3" :managerPositions="moderatorStatistics.managerPositions"/>
          <MastersProductionPanel :mastersProduction="moderatorStatistics.mastersProduction" :isAdmin="false"/>
        </div>
        <div v-else-if="isMaster && $store.state.user" style="margin: 10vh auto;" class="p-text-center">
          <h1 class="p-mb-6">{{ $t('Welcome') }}, {{ $store.state.user.first_name }} {{ $store.state.user.last_name }}.</h1>
          <h5 class="">{{ $t('This page is under construction') }}.</h5>
        </div>
      </div>

<!--      <div v-else-if="isMaster && $store.state.user" class="card">-->
<!--        <Toolbar class="p-mb-4">-->
<!--          &lt;!&ndash;          <template v-slot:left>&ndash;&gt;-->
<!--          <template #start>-->
<!--            <div style="font-size: 1.4em;">{{ $t('Dashboard') }}</div>-->
<!--          </template>-->
<!--          &lt;!&ndash;          <template v-slot:right>&ndash;&gt;-->
<!--        </Toolbar>-->
<!--        <div style="margin: 10vh auto; " class="p-text-center">-->
<!--          <h1 class="p-mb-6">{{ $t('Welcome') }}, {{ $store.state.user.first_name }} {{ $store.state.user.last_name }}.</h1>-->
<!--          <h5 class="">{{ $t('This page is under construction') }}.</h5>-->
<!--        </div>-->
<!--      </div>-->
    </div>

<!--		<div class="p-col-12 p-md-12">-->
<!--      <PaymentsPanel :payments="adminStatistics.payments"/>-->
<!--		</div>-->

<!--    <div class="p-col-12 p-md-12">-->
<!--      <PositionTypesPanel :positionTypes="adminStatistics.positionTypes"/>-->
<!--    </div>-->

<!--    <div class="p-col-12">-->
<!--      <ManagerSalesPanel :managerSales="adminStatistics.managerSales"/>-->
<!--    </div>-->

<!--    <div class="p-col-12">-->
<!--      <MastersProductionPanel :mastersProduction="adminStatistics.mastersProduction"/>-->
<!--    </div>-->



	</div>

<!--  <div v-else>-->
<!--    No data-->
<!--  </div>-->
</template>

<script>
import httpClient from "@/services/http.services";
// import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import DatesRangeCalendar from "@/components/DatesRangeCalendar";
import constants from "@/constants";
import PaymentsPanel from "@/pages/statistics/components/PaymentsPanel";
// import PositionTypesPanel from "@/pages/statistics/components/PositionTypesPanel";
import ManagerSalesPanel from "@/pages/statistics/components/ManagerSalesPanel";
import MastersProductionPanel from "@/pages/statistics/components/MastersProductionPanel";
import ManagerCommonPanel from "@/pages/statistics/components/ManagerCommonPanel";
import InvoicesPanel from "@/pages/statistics/components/InvoicesPanel";
import datatableMixins from "@/mixins/datatableMixins";
import ManagerPositionsPanel from "@/pages/statistics/components/ManagerPositionsPanel";
import ConsumablesPanel from "@/pages/statistics/components/ConsumablesPanel";
import calculateMixins from "@/mixins/calculateMixins";
import settings from "@/settings";
import permissionsMixins from "@/mixins/permissionsMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ formatMixins, datatableMixins, calculateMixins, permissionsMixins, showErrorsMixins ],
  components: {
    DatesRangeCalendar,
    PaymentsPanel,
    ManagerSalesPanel,
    MastersProductionPanel,
    ManagerCommonPanel,
    InvoicesPanel,
    ConsumablesPanel,
    ManagerPositionsPanel
  },
  data() {
    return {
      // axiosController: null,
      isAdmin: false,
      isModerator: false,
      isMaster: false,
      constants,
      adminStatistics: {
        invoices: null,
        consumablesStat: null,
        payments: {
          card: {},
          cash: {},
          bank: {},
          total_sum: 0,
          total_count: 0,
        },
        // positionTypes: {
        //   services: {},
        //   requests: {},
        //   warehouse_positions: {},
        //   free_positions: {},
        //   consumables: {},
        //   total_sum: 0,
        //   total_count: 0,
        // },
        orders_created: [],
        managerCommonStat: [],
        managerSales: [],
        mastersProduction: [],
      },
      moderatorStatistics: {
        managerPositions: {},
        mastersProduction: [],
      }
    }
  },
  watch: {
    '$store.state.user'() {
      this.getPageData()
    }
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    // this.isLoading = true
    this.generateThisMonthRange()
    this.getPageData()
  },
  methods: {
    getPageData() {
      if (!this.$store.state.user?.role) return false
      this.checkUserRole()
      this.getStatistics()
    },
    checkUserRole() {
      const superAdmin = constants.userRoles.superAdmin
      const admin = constants.userRoles.admin
      const moderator = constants.userRoles.moderator
      const accountant = constants.userRoles.accountant
      const employeeWorkshopMaster = constants.userRoles.employeeWorkshopMaster
      const employeeMaster = constants.userRoles.employeeMaster

      if (this.$store.state.user.role === superAdmin || this.$store.state.user.role === admin || this.$store.state.user.role === accountant) {
        this.isAdmin = true
        this.isModerator = false
        this.isMaster = false
      } else if (this.$store.state.user.role === moderator) {
        this.isAdmin = false
        this.isModerator = true
        this.isMaster = false
      } else if (this.$store.state.user.role === employeeWorkshopMaster || this.$store.state.user.role === employeeMaster) {
        this.isAdmin = false
        this.isModerator = false
        this.isMaster = true
      }
    },
    changeDatesRange({ fromTimestamp, toTimestamp }, loadData = false) {
      this.fromTimestamp = fromTimestamp
      this.toTimestamp = toTimestamp
      if (loadData) {
        // console.log('load data')
        // this.getAdminStatistics()
        this.getStatistics()
      }
    },
    getStatistics() {
      // console.log(this.axiosController)
      // this.axiosController?.abort()
      if (this.isAdmin) {
        this.getAdminStatistics()
      } else if (this.isModerator) {
        this.getModeratorStatistics()
      } else if (this.isMaster) {
        this.isLoading = false
      }
    },
    async getAdminStatistics() {
      // if (!this.showStatistics) return false
      // this.axiosController = new AbortController();
      this.dataTableIsLoading = true
      // console.log('getAdminStatistics')
      try {
        const { status, data } = await httpClient({
          url: 'statistics/get-admin-statistics',
          method: 'post',
          // params: {
          //   page: this.pagination.currentPage,
          //   'per-page': this.pagination.perPage,
          //   sort: this.sort.direction + this.sort.column,
          //   search: this.searchData,
          //   expand: this.fieldsExpand,
          // },
          // signal: this.axiosController.signal,
          data: {
            // userId: this.$route.params.id,
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })
        // const { status, data } = await httpClient.post('statistics/get-statistics')

        if (status === 200 && data) {
          // if (data.empty) {
          //   this.adminStatistics = null
          // }
          // this.adminStatistics.salesInvoices = data.sales_invoices?.count ? data.sales_invoices : null
          this.adminStatistics.invoices = data.invoices ?? null

          this.adminStatistics.consumablesStat = data.consumables ?? null

          if (data.payments) {
            this.adminStatistics.payments = {}
            this.adminStatistics.payments.card = data.payments.card
            this.adminStatistics.payments.cash = data.payments.cash
            this.adminStatistics.payments.bank = data.payments.bank

            this.adminStatistics.payments.total_sum = +data.payments.card.sum + +data.payments.cash.sum + +data.payments.bank.sum
            this.adminStatistics.payments.total_count = +data.payments.card.count + +data.payments.cash.count + +data.payments.bank.count
          } else {
            this.adminStatistics.payments = null
          }

          // if (data.position_types) {
          //   this.adminStatistics.positionTypes.services = data.position_types.services ?? {}
          //   this.adminStatistics.positionTypes.requests = data.position_types.requests ?? {}
          //   this.adminStatistics.positionTypes.warehouse_positions = data.position_types.warehouse_positions ?? {}
          //   this.adminStatistics.positionTypes.free_positions = data.position_types.free_positions ?? {}
          //   this.adminStatistics.positionTypes.consumables = data.position_types.consumables ?? {}
          //
          //   const sum = +data.position_types.services.sum + +data.position_types.requests.sum + +data.position_types.warehouse_positions.sum + +data.position_types.free_positions.sum + +data.position_types.consumables.sum
          //   const count = +data.position_types.services.count + +data.position_types.requests.count + +data.position_types.warehouse_positions.count + +data.position_types.free_positions.count + +data.position_types.consumables.count
          //   this.adminStatistics.positionTypes.total_sum = sum
          //   this.adminStatistics.positionTypes.total_count = count
          // }

          this.adminStatistics.managerCommonStat = data.manager_common_stat || []

          // this.adminStatistics.managerSales = data.manager_sales || []

          this.adminStatistics.mastersProduction = data.masters_production || []

          // if (data.manager_sales) {
            // console.log('data.manager_sales')
            // this.adminStatistics.managerSales = data.manager_sales
            // console.log(data.manager_sales)
            // this.adminStatistics.positionTypes.services = data.position_types.services
            // this.adminStatistics.positionTypes.requests = data.position_types.requests
            // this.adminStatistics.positionTypes.warehouse = data.position_types.warehouse
            // this.adminStatistics.positionTypes.free = data.position_types.free
            // this.adminStatistics.positionTypes.consumables = data.position_types.consumables
          // } else {
          //   this.adminStatistics.managerSales = []
          // }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
      }
    },
    async getModeratorStatistics() {
      // if (!this.showStatistics) return false
      this.dataTableIsLoading = true
      // console.log('getAdminStatistics')
      try {
        const { status, data } = await httpClient({
          url: 'statistics/get-moderator-statistics',
          method: 'post',
          data: {
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
            userId: this.$store.state.user.id
          }
        })

        if (status === 200 && data) {
          this.moderatorStatistics.managerPositions = data.manager_positions || {}
          this.moderatorStatistics.mastersProduction = data.masters_production || []
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
      }
    },
  },
  computed: {
    computedMinDate() {
      if (this.userIsAdminOrAccountant) {
        return null
      } else {
        let d = new Date()
        d.setMonth(d.getMonth() - 3)
        return d
      }
    },
  //   showStatistics() {
  //     return !!(this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin || this.$store.state.user.role === constants.userRoles.accountant)
  //   }
  }
}
</script>

<style lang="scss">
.datatable-wrapper {
  overflow-y: auto;
}

.positions-table {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  tr {
    th {
      padding: 8px !important;
    }
    td {
      padding: 8px !important;
    }
  }
}

.table-opened-info__row--total {
  font-weight: 500;
}

.no-bottom-border {
  border-bottom: none !important;
}
</style>